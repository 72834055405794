@import url(https://fonts.googleapis.com/css2?family=Alex+Brush&family=Amatic+SC&family=Amita&family=Bad+Script&family=Borel&family=Butterfly+Kids&family=Cookie&family=Damion&family=Dancing+Script&family=Dr+Sugiyama&family=Felipa&family=Grand+Hotel&family=Great+Vibes&family=Herr+Von+Muellerhoff&family=Julee&family=Kaushan+Script&family=Lugrasimo&family=Molle&family=Ms+Madi&family=Pacifico&family=Patrick+Hand&family=Qwitcher+Grypen&family=Sacramento&family=Sofia&family=Tangerine:wght@700&family=Waterfall&family=Yellowtail&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.CircularProgressbar {
  width: 50%;
}
/* .CircularProgressbar-path {
  stroke: red;
}
.CircularProgressbar-trail {
  stroke: gray;
}
.CircularProgressbar-text {
  fill: yellow;
}
.CircularProgressbar-background {
  fill: green;
} */

.styles_sigPad__20zCh {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.styles_sigPad__3XKcM {
  width: 100%;

  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 2px 3px rgba(255, 255, 255, 0.3),
    inset 0 -2px 3px rgba(0, 0, 0, 0.3), 0 1px 1px rgba(255, 255, 255, 0.9);
}

#signature_div_msa {
  width: 48%;
  float: right;
}
@media (max-width: 1250px) {
}
@media (max-width: 1238px) {
}

@media (max-width: 1199px) {
}
@media (max-width: 1120px) {
}
@media (max-width: 1084px) {
}
@media (max-width: 1066px) {
}
@media (max-width: 1046px) {
}
@media (max-width: 991px) {
}
@media (max-width: 958px) {
}
@media (max-width: 868px) {
}
@media (max-width: 768px) {
  #signature_div_msa {
    width: 100%;
  }
}
@media (max-width: 612px) {
  #signature_div_msa {
    width: 100%;
  }
}
@media (max-width: 577px) {
  #signature_div_msa {
    width: 100%;
  }
}
@media (max-width: 531px) {
  #signature_div_msa {
    width: 100%;
  }
}
@media (max-width: 462px) {
  #signature_div_msa {
    width: 100%;
  }
}
@media (max-width: 360px) {
  #signature_div_msa {
    width: 100%;
  }
}

.styles_sigPad__3N7MQ {
  width: 100%;

  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 2px 3px rgba(255, 255, 255, 0.3),
    inset 0 -2px 3px rgba(0, 0, 0, 0.3), 0 1px 1px rgba(255, 255, 255, 0.9);
}

#signature_div {
  width: 55%;
  float: right;
}
@media (max-width: 1250px) {
}
@media (max-width: 1238px) {
}

@media (max-width: 1199px) {
}
@media (max-width: 1120px) {
}
@media (max-width: 1084px) {
}
@media (max-width: 1066px) {
}
@media (max-width: 1046px) {
}
@media (max-width: 991px) {
}
@media (max-width: 958px) {
}
@media (max-width: 868px) {
}
@media (max-width: 768px) {
  #signature_div {
    width: 100%;
  }
}
@media (max-width: 612px) {
  #signature_div {
    width: 100%;
  }
}
@media (max-width: 577px) {
  #signature_div {
    width: 100%;
  }
}
@media (max-width: 531px) {
  #signature_div {
    width: 100%;
  }
}
@media (max-width: 462px) {
  #signature_div {
    width: 100%;
  }
}
@media (max-width: 360px) {
  #signature_div {
    width: 100%;
  }
}

.tab-agreement_content ol,
.tab-agreement_content ul,
.tab-agreement_content li {
  margin-left: 20px !important;
}

.tab-agreement_content>.tab-pane.active>div>ol,
.tab-agreement_content>.tab-pane.active>div>ul {
  list-style: inherit !important;
}

.styles_sigPad__3xvjb {
  width: 100%;

  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  
  
}




#signature_div {
  width: 65%;
  float: right;
}
@media (max-width: 1250px) {
}
@media (max-width: 1238px) {
}

@media (max-width: 1199px) {
}
@media (max-width: 1120px) {
}
@media (max-width: 1084px) {
}
@media (max-width: 1066px) {
}
@media (max-width: 1046px) {
}
@media (max-width: 991px) {
}
@media (max-width: 958px) {
}
@media (max-width: 868px) {
}
@media (max-width: 768px) {
  #signature_div {
    width: 100%;
  }
}
@media (max-width: 612px) {
  #signature_div {
    width: 100%;
  }
}
@media (max-width: 577px) {
  #signature_div {
    width: 100%;
  }
}
@media (max-width: 531px) {
  #signature_div {
    width: 100%;
  }
}
@media (max-width: 462px) {
  #signature_div {
    width: 100%;
  }
}
@media (max-width: 360px) {
  #signature_div {
    width: 100%;
  }
}

.tab-agreement_content ol,
.tab-agreement_content ul,
.tab-agreement_content li {
  margin-left: 20px !important;
}

.tab-agreement_content>.tab-pane.active>div>ol,
.tab-agreement_content>.tab-pane.active>div>ul {
  list-style: inherit !important;
}

.agreement_input_textfield,
.agreement_input_textarea,
.agreement_input_checkbox,
.agreement_input_radio,
.agreement_input_dropdown {
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  outline: none;
  margin-bottom: 10px;
}

.agreement_input_checkbox,
.agreement_input_radio {
  margin-right: 5px;
}

.agreement_input_dropdown {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23333" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 8px top 50%;
  background-size: 18px;
  padding-right: 30px;
}

.agreement_input_dropdown option {
  font-size: 14px;
}

.CircularProgressbar {
  width: 50%;
}
/* .CircularProgressbar-path {
  stroke: red;
}
.CircularProgressbar-trail {
  stroke: gray;
}
.CircularProgressbar-text {
  fill: yellow;
}
.CircularProgressbar-background {
  fill: green;
} */

/*font-family: 'Alex Brush', cursive;
font-family: 'Amatic SC', cursive;
font-family: 'Amita', cursive;
font-family: 'Bad Script', cursive;
font-family: 'Borel', cursive;
font-family: 'Butterfly Kids', cursive;
font-family: 'Cookie', cursive;
font-family: 'Damion', cursive;
font-family: 'Dancing Script', cursive;
font-family: 'Dr Sugiyama', cursive;
font-family: 'Felipa', cursive;
font-family: 'Grand Hotel', cursive;
font-family: 'Great Vibes', cursive;
font-family: 'Herr Von Muellerhoff', cursive;
font-family: 'Julee', cursive;
font-family: 'Kaushan Script', cursive;
font-family: 'Lugrasimo', cursive;
font-family: 'Molle', cursive;
font-family: 'Ms Madi', cursive;
font-family: 'Pacifico', cursive;
font-family: 'Patrick Hand', cursive;
font-family: 'Qwitcher Grypen', cursive;
font-family: 'Sacramento', cursive;
font-family: 'Sofia', cursive;
font-family: 'Tangerine', cursive;
font-family: 'Waterfall', cursive;
font-family: 'Yellowtail', cursive;*/



.signature-preview {
  margin-top: 20px;
  font-size: 18px;
}

/*Signature Classes*/
.Alex-Brush{font-family: "'Alex Brush', cursive"}
.Amatic-SC{font-family: "'Amatic SC', cursive"}
.Amita{font-family: "'Amita', cursive"}
.Bad-Script{font-family: "'Bad Script', cursive"}
.Borel{font-family: "'Borel', cursive"}
.Butterfly-Kids{font-family: "'Butterfly Kids', cursive"}
.Cookie{font-family: "'Cookie', cursive"}
.Damion{font-family: "'Damion', cursive"}
.Dancing-Script{font-family: "'Dancing Script', cursive"}
.Dr-Sugiyama{font-family: "'Dr Sugiyama', cursive"}
.Felipa{font-family: "'Felipa', cursive"}
.Grand-Hotel{font-family: "'Grand Hotel', cursive"}
.Great-Vibes{font-family: "'Great Vibes', cursive"}
.Herr-Von-Muellerhoff{font-family: "'Herr Von Muellerhoff', cursive"}
.Julee{font-family: "'Julee', cursive"}
.Kaushan-Script{font-family: "'Kaushan Script', cursive"}
.Lugrasimo{font-family: "'Lugrasimo', cursive"}
.Molle{font-family: "'Molle', cursive"}
.Ms-Madi{font-family: "'Ms Madi', cursive"}
.Pacifico{font-family: "'Pacifico', cursive"}
.Patrick-Hand{font-family: "'Patrick Hand', cursive"}
.Qwitcher-Grypen{font-family: "'Qwitcher Grypen', cursive"}
.Sacramento{font-family: "'Sacramento', cursive"}
.Sofia{font-family: "'Sofia', cursive"}
.Tangerine{font-family: "'Tangerine', cursive"}
.Waterfall{font-family: "'Waterfall', cursive"}
.Yellowtail{font-family: "'Yellowtail', cursive"}

.sigPadContainer {
  background-color: rgb(238, 235, 235);
  padding: 0 10px 10px;
}

.sigPadContainer hr{
  margin-bottom: unset;
  background-color: gainsboro;
}

.sigCanvas{
  width: 100%;
  height: 150px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

/*#modalSignAndPay.modalContainer {
  position: fixed;
  width: 100vw; 
  height: 100%;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

#modalSignAndPay.modal{
  width: 100%;
  max-width: 500px;
  border: 5px;
  padding: 10px;
  background-color: white;
  box-sizing: border-box;
}

.modal__bottom {
  text-align: right;
  margin-top: 10px;
}

.signature {
  width: 200px;
  height: 70px;
  margin-top: 20px;
  box-shadow: 0 0 4px 2px gainsboro;
  padding: 10px;
  object-fit: contain;
}*/



